import { FC } from "react"
import { ComponentsConfig } from "./components-config"
import Header from "@trades-org/waas-component-library/dist/components/organisms/header/header"
import Hero from "@trades-org/waas-component-library/dist/components/organisms/hero/hero"
import BusinessFeatures from "@trades-org/waas-component-library/dist/components/organisms/business-features/business-features"
import Services from "@trades-org/waas-component-library/dist/components/organisms/services/services"
import CompletedProjects from "@trades-org/waas-component-library/dist/components/organisms/completed-projects/completed-projects"
import OurProcess from "@trades-org/waas-component-library/dist/components/organisms/our-process/our-process"
import Testimonials from "@trades-org/waas-component-library/dist/components/organisms/testimonials/testimonials"
import Guarantees from "@trades-org/waas-component-library/dist/components/organisms/guarantees/guarantees"
import Faqs from "@trades-org/waas-component-library/dist/components/organisms/faqs/faqs"
import ServiceArea from "@trades-org/waas-component-library/dist/components/organisms/service-area/service-area"
import Footer from "@trades-org/waas-component-library/dist/components/organisms/footer/footer"
import { NewHorizonHomePageType } from "./new-horizon.types"

export const HomeHelper: FC<NewHorizonHomePageType> = ({
    headerData,
    heroHeaderData,
    heroFooterData,
    businessFeaturesData,
    servicesData,
    completedProjectsData,
    ourProcessData,
    testimonialsData,
    faqsData,
    serviceAreaData,
    guaranteesData,
    footerData,
}) => {
    const {
        headerData: headerDataSettings,
        heroHeaderData: heroHeaderDataSettings,
        heroFooterData: heroFooterDataSettings,
        businessFeaturesData: businessFeaturesDataSettings,
        servicesData: servicesDataSettings,
        completedProjectsData: completedProjectsDataSettings,
        ourProcessData: ourProcessDataSettings,
        testimonialsData: testimonialsDataSettings,
        faqsData: faqsDataSettings,
        guaranteesData: guaranteesDataSettings,
        serviceAreaData: serviceAreaDataSettings,
        footerData: footerDataSettings,
    } = ComponentsConfig.homePage

    return (
        <main>
            <Header {...headerData} {...headerDataSettings} />
            <Hero {...heroHeaderData} {...heroHeaderDataSettings} />
            <BusinessFeatures {...businessFeaturesData} {...businessFeaturesDataSettings} />
            <Services {...servicesData} {...servicesDataSettings} />
            <div className={"bg-gray-50 md:pt-5 xl:pt-7 pt-10"}>
                <CompletedProjects {...completedProjectsData} {...completedProjectsDataSettings} />
            </div>
            <OurProcess {...ourProcessData} {...ourProcessDataSettings} />
            <Testimonials {...testimonialsData} {...testimonialsDataSettings} />
            <Guarantees {...guaranteesData} {...guaranteesDataSettings} />
            <Faqs {...faqsData} {...faqsDataSettings} />
            <ServiceArea {...serviceAreaData} {...serviceAreaDataSettings} />
            <Hero {...heroFooterData} {...heroFooterDataSettings} />
            <Footer {...footerData} {...footerDataSettings} />
        </main>
    )
}

export default HomeHelper
